#taskgridContainer {
  height: 97vh;
    margin: 3px;
    width: 99%;
  }
  
  #taskgridContainer .dx-treelist-rowsview td {
    vertical-align: middle;
  }
  
  .img,
  .name {
    display: inline-block;
    vertical-align: middle;
  }
  
  .img {
    height: 50px;
    width: 50px;
    margin-right: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 25px;
    border: 1px solid lightgray;
    background-color: #fff;
  }
  .green-cell{
    background-color: green;
    color: black;
  }
  .red-cell{
    background-color: red;
    color: black;
  }
.yellow-cell{
background-color: yellow;
color: black;
}
.checkbox-cell{
  text-align: center;
}
.strike-through{
  text-decoration: line-through;
}


.dx-row img {
  height: 100px;
}
#taskgridContainer tr.main-row {
  font-size: 0.7em;
}
#taskgridContainer tr.notes-row {
  white-space: normal;
  vertical-align: top;
}

#taskgridContainer tr.notes-row td {
  height: 40px;
  font-size: 0.8em;
}
#taskgridContainer tbody.dx-state-hover {
  background-color: #ebebeb;
}

.dark #taskgridContainer tbody.dx-state-hover {
  background-color: #484848;
}

#taskgridContainer tbody.dx-state-hover tr.main-row td {
  color: #000;
}

.dark #taskgridContainer tbody.dx-state-hover tr.main-row td {
  color: #ccc;
}

#taskgridContainer tbody.dx-state-hover tr.notes-row td {
  color: #888;
}
.heading{
  display: inline;
  font-weight: bold;
}
.paragraph{
  margin-left: 5px;
  display: inline;
  color: #888;
}
.data-field{
  margin-bottom: 4px;
}
