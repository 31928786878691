.File-logo{
    text-align: center;
}
#gridContainer {
    max-height: 540px;
    margin: 10px;
  }
  
  #gridContainer .dx-treelist-rowsview td {
    vertical-align: middle;
  }